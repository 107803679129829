import { IcoCheck } from '@src/assets/svg';
import { Button, Card, Typography } from 'antd';

interface SettingsSubscriptionPlanCardProps {
  name: string;
  price: number;
  titleColor: string;
  buttonColor?: string;
  descriptions: string[];
  features: string[];
  onSelect?: () => void;
  text: React.ReactNode;
}
const SettingsSubscriptionPlanCard = (
  props: SettingsSubscriptionPlanCardProps,
) => {
  const { name, price, titleColor, buttonColor, descriptions, features } =
    props;

  return (
    <Card
      bodyStyle={{ padding: '24px' }}
      style={{
        width: '340px',
        minHeight: '410px',
        borderColor: titleColor,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography.Title
          style={{
            fontSize: '36px',
            fontWeight: 800,
            marginBottom: '0',
            color: titleColor,
            lineHeight: '47px',
          }}
        >
          {name}
        </Typography.Title>
        <Typography.Text
          style={{
            fontSize: '24px',
            fontWeight: 800,
            margin: '16px 0',
            marginTop: '10px',
            lineHeight: '31px',
            color: titleColor,
          }}
        >
          {price.toLocaleString()}원 / 월
        </Typography.Text>
      </div>
      <Button
        onClick={props.onSelect}
        style={{
          borderRadius: '12px',
          backgroundColor: buttonColor || titleColor,
          color: 'white',
          padding: '11px 19px',
          fontSize: '14px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {props.text}
      </Button>
      <br />
      <p style={{ color: '#57616B', lineHeight: '145%' }}>
        {descriptions.map((d) => {
          return (
            <span key={d}>
              {d}
              <br />
            </span>
          );
        })}
      </p>
      <div>
        {features.map((f) => {
          return (
            <div
              key={f}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <IcoCheck
                  width={18}
                  height={18}
                  fill={titleColor}
                  style={{ marginRight: '8px' }}
                />
              </span>
              {f} <br />
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default SettingsSubscriptionPlanCard;
