import { Tabs, TabsProps } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import { TeambinderApiService } from '@src/api';
import Utils from '@src/utils/utils';
import { useQuery } from '@tanstack/react-query';
import TabInfo from './TabInfo';
import TabPlans from './TabPlans';
import TabCards from './TabCards';
import TabSubscriptionHistory from './TabSubscriptionHistory';
import { useNavigate, useSearchParams } from 'react-router-dom';

const tabs: TabsProps['items'] = [
  {
    key: '1',
    label: '기본 정보',
    children: <TabInfo />,
  },
  {
    key: '2',
    label: '구독 관리',
    children: <TabPlans />,
  },
  {
    key: '3',
    label: '구독 내역',
    children: <TabSubscriptionHistory />,
  },
  {
    key: '4',
    label: '결제카드 관리',
    children: <TabCards />,
  },
];
const SettingsMy = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: userResponse } = useQuery(
    ['member'],
    TeambinderApiService.getPersonalSettings,
  );
  const isMobile = Utils.isMobile();

  return (
    <Scrollbars>
      <div
        style={{
          padding: isMobile ? '20px' : '',
        }}
      >
        <h1
          style={{
            color: '#4403B5',
            marginBottom: '20px',
            fontWeight: 'bold',
          }}
        >
          설정
        </h1>
        {userResponse?.data?.username && (
          <h2>안녕하세요! {userResponse?.data?.username || ''}님!</h2>
        )}
        <Tabs
          defaultActiveKey={searchParams.get('tab') || '1'}
          activeKey={searchParams.get('tab') || '1'}
          items={tabs}
          className="setting"
          onChange={(key) => {
            setSearchParams(`tab=${key}`);
          }}
        />
      </div>
    </Scrollbars>
  );
};

export default SettingsMy;
