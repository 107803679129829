import { TeambinderApiService } from '@src/api';
import { useQuery } from '@tanstack/react-query';
import SettingsSubscriptionPlanCard from '../setting/SettingSubscriptionPlanCard';
import { Modal } from 'antd';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
const colors = [
  { titleColor: '#57616B', buttonColor: '#B5BDC5' },
  { titleColor: '#3294EE' },
  { titleColor: '#24C185' },
];
const plans = [
  {
    name: 'FREE',
    price: 0,
    description: '바인더가 처음이신가요?\n부담없이 모든 기능을 사용해보세요',
    features: [
      '키워드 모니터링 제공',
      '카카오 알림톡 월 4개 발송',
      '키워드 설정 10개',
    ],
    onSubscribe: async () => {
      await TeambinderApiService.changeSubscription({
        requestBody: {
          subscriptionPlan: 'FREE',
          billingCycle: 'INFINITE',
        },
      });
    },
  },
  {
    name: 'BASIC',
    price: 4900,
    description:
      '매일 새로운 소식을 손쉽게 받아보세요.\n보다 쾌적한 환경을 제공합니다.',
    features: [
      '키워드 모니터링 제공',
      '카카오 알림톡 월 35개 발송',
      '키워드 설정 35개',
      '인스타그램 검색 제공',
      '유튜브 검색 제공',
    ],
    onSubscribe: async () => {
      await TeambinderApiService.changeSubscription({
        requestBody: {
          subscriptionPlan: 'BASIC',
          billingCycle: 'MONTHLY',
        },
      });
    },
  },
  {
    name: 'PREMIUM',
    price: 19900,
    description:
      '대용량 데이터 확인이 필요하신가요?\n중견기업과 스타트업에 추천합니다',
    features: [
      '키워드 모니터링 제공',
      '카카오 알림톡 무제한 발송',
      '키워드 설정 50개',
      '인스타그램 검색 제공',
      '유튜브 검색 제공',
    ],
    onSubscribe: async () => {
      await TeambinderApiService.changeSubscription({
        requestBody: {
          subscriptionPlan: 'PREMIUM',
          billingCycle: 'MONTHLY',
        },
      });
    },
  },
];

const TabPlans = () => {
  const navigate = useNavigate();
  const { data: currentSubscription } = useQuery(
    ['current-subscription'],
    () => TeambinderApiService.getLatestSubscription({ q: 'latest' }),
    { refetchInterval: 3000 },
  );
  const cardList = useQuery(
    ['cardList'],
    TeambinderApiService.findPaymentMethods,
  );
  const currentsSubscriptionPlanName =
    currentSubscription?.data?.plan?.subscriptionPackage?.subscriptionPlan;

  const onSelectPlan = async (plan: typeof plans[0]) => {
    if (currentsSubscriptionPlanName === plan.name) {
      return;
    }
    try {
      if (plan.name !== 'FREE' && cardList.data?.data?.length === 0) {
        Modal.confirm({
          title: '결제 카드 등록이 필요합니다. 카드를 등록하시겠습니까?',
          onOk: () => {
            navigate(`/app/my?tab=4&loadToss=true&plan=${plan.name}`);
          },
          okText: '카드 등록하기',
          cancelText: '취소',
        });
        return;
      }

      Modal.confirm({
        title: `${plan.name} 플랜으로 변경하시겠습니까?`,
        onOk: async () => {
          await plan.onSubscribe();
          Modal.info({
            title: `${plan.name} 플랜으로 변경되었습니다.`,
            onOk: () => {
              setTimeout(() => {
                window.location.reload();
              }, 500);
            },
          });
        },
        okText: '변경하기',
        cancelText: '취소',
      });
    } catch (e) {
      Modal.info({
        title: '구독 플랜 변경에 실패했습니다.',
      });
    }
  };

  const [searchParams] = useSearchParams();
  const selectedPlan = searchParams.get('selectedPlan');
  useEffect(() => {
    if (!selectedPlan) {
      return;
    }

    navigate(`/app/my?tab=2`, { replace: true });
    const plan = plans.find((p) => p.name === selectedPlan);
    if (plan) {
      onSelectPlan(plan);
    }
  }, [selectedPlan]);

  return (
    <div
      style={{
        display: 'flex',
        gap: '16px',
        paddingLeft: 0,
        fontSize: '20px',
        overflow: 'scroll',
      }}
    >
      {plans.map((plan, index) => (
        <div key={plan.name}>
          <SettingsSubscriptionPlanCard
            name={plan.name || ''}
            titleColor={colors[index].titleColor}
            buttonColor={colors[index].buttonColor}
            price={plan.price || 0}
            descriptions={plan.description?.split('\n') || []}
            features={plan.features || []}
            text={
              currentsSubscriptionPlanName === plan.name ? (
                <b>현재 사용중</b>
              ) : (
                '변경하기'
              )
            }
            onSelect={() => onSelectPlan(plan)}
          />
        </div>
      ))}
    </div>
  );
};

export default TabPlans;
