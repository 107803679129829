/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicResponseBinderAccessToken } from '../models/BasicResponseBinderAccessToken';
import type { BasicResponseBoolean } from '../models/BasicResponseBoolean';
import type { BasicResponseGetBinderMemberResponse } from '../models/BasicResponseGetBinderMemberResponse';
import type { BasicResponseListBinderEntity } from '../models/BasicResponseListBinderEntity';
import type { BasicResponseListBinderKeyword } from '../models/BasicResponseListBinderKeyword';
import type { BasicResponseListPaymentHistoryItem } from '../models/BasicResponseListPaymentHistoryItem';
import type { BasicResponseListPaymentMethodItem } from '../models/BasicResponseListPaymentMethodItem';
import type { BasicResponsePersonalSettings } from '../models/BasicResponsePersonalSettings';
import type { BasicResponseString } from '../models/BasicResponseString';
import type { BasicResponseStringValueObject } from '../models/BasicResponseStringValueObject';
import type { BasicResponseSubscriptionInfo } from '../models/BasicResponseSubscriptionInfo';
import type { BasicResponseVoid } from '../models/BasicResponseVoid';
import type { BinderSignInViaKakaoCommand } from '../models/BinderSignInViaKakaoCommand';
import type { IssueBillingKeyRequest } from '../models/IssueBillingKeyRequest';
import type { ModifyEmailRequest } from '../models/ModifyEmailRequest';
import type { ModifyPhoneNumberRequest } from '../models/ModifyPhoneNumberRequest';
import type { SubscriptionPackage } from '../models/SubscriptionPackage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeambinderApiService {

    /**
     * 바인더 이름 변경
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static editBinder({
        binderId,
        name,
    }: {
        binderId: string,
        name: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder/{binderId}',
            path: {
                'binderId': binderId,
            },
            query: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 수정
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyBinderKeywords({
        binderId,
        keywordId,
        name,
        type,
        exclusions = '',
    }: {
        binderId: string,
        keywordId: string,
        name: string,
        type: 'NaverNews' | 'NaverBlog' | 'NaverCafe' | 'Youtube' | 'Twitter' | 'Instagram',
        exclusions?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder/{binderId}/keywords/{keywordId}',
            path: {
                'binderId': binderId,
                'keywordId': keywordId,
            },
            query: {
                'name': name,
                'type': type,
                'exclusions': exclusions,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteBinderKeyword({
        binderId,
        keywordId,
    }: {
        binderId: string,
        keywordId: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/binder/{binderId}/keywords/{keywordId}',
            path: {
                'binderId': binderId,
                'keywordId': keywordId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 순서 조정
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static reorderBinder({
        targetBinderId,
        prevBinderId = '',
    }: {
        targetBinderId: string,
        prevBinderId?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder/reorder',
            query: {
                'targetBinderId': targetBinderId,
                'prevBinderId': prevBinderId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 구독 정보 조회
     * 사용자가 현재 구독중인 플랜에 대한 정보를 조회합니다.
     *
     * @returns BasicResponseSubscriptionInfo OK
     * @throws ApiError
     */
    public static getLatestSubscription({
        q,
    }: {
        /**
         * 조회 조건을 지정합니다. 'latest' 값으로 설정해야 합니다.
         */
        q: string,
    }): CancelablePromise<BasicResponseSubscriptionInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/members/me/subscriptions',
            query: {
                'q': q,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 구독 플랜 변경
     * 현재 구독중인 플랜을 변경합니다.<br>
     * 무료 플랜으로 변경할 경우, subscriptionPlan 값은 `FREE`, billingCycle 값은 `INFINITE` 로 설정해야 합니다.<br>
     * 유료 플랜으로 변경할 경우, subscriptionPlan 값은 `BASIC` 또는 `PREMIUM`, billingCycle 값은 `MONTHLY` 로 설정해야 합니다.<br>
     * 그 외의 입력값은 모두 실패처리 됩니다.
     *
     * @returns BasicResponseSubscriptionInfo OK
     * @throws ApiError
     */
    public static changeSubscription({
        requestBody,
    }: {
        requestBody: SubscriptionPackage,
    }): CancelablePromise<BasicResponseSubscriptionInfo> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-app/members/me/subscriptions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 설정 값 변경
     * setting-name (설정 이름)
     * * 카카오 알림 수신 여부: `notification.kakao.enabled`
     * * 이메일 수신 여부: `notification.email.enabled`
     *
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyPersonalSettings({
        settingName,
        value,
    }: {
        /**
         * 설정 이름
         */
        settingName: string,
        /**
         * 설정 값
         */
        value: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-app/members/me/settings/{setting-name}',
            path: {
                'setting-name': settingName,
            },
            query: {
                'value': value,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 휴대폰 번호 변경
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyPhoneNumber({
        requestBody,
    }: {
        requestBody: ModifyPhoneNumberRequest,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-app/members/me/phone-number',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * email 주소 번호 변경
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyEmail({
        requestBody,
    }: {
        requestBody: ModifyEmailRequest,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-app/members/me/email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 목록 조회
     * @returns BasicResponseListBinderEntity OK
     * @throws ApiError
     */
    public static getBinderList(): CancelablePromise<BasicResponseListBinderEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binders',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 추가
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static addBinder({
        name,
    }: {
        name: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binders',
            query: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 조회
     * @returns BasicResponseListBinderKeyword OK
     * @throws ApiError
     */
    public static getBinderKeywords({
        binderId,
    }: {
        binderId: string,
    }): CancelablePromise<BasicResponseListBinderKeyword> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder/{binderId}/keywords',
            path: {
                'binderId': binderId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 추가
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static addBinderKeywords({
        binderId,
        name,
        type,
        exclusions = '',
    }: {
        binderId: string,
        name: string,
        type: 'NaverNews' | 'NaverBlog' | 'NaverCafe' | 'Youtube' | 'Twitter' | 'Instagram',
        exclusions?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binder/{binderId}/keywords',
            path: {
                'binderId': binderId,
            },
            query: {
                'name': name,
                'type': type,
                'exclusions': exclusions,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 순서 변경
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static reorderKeyword({
        binderId,
        targetItemId,
        prevItemId = '',
    }: {
        binderId: string,
        targetItemId: string,
        prevItemId?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binder/{binderId}/keywords/reorder',
            path: {
                'binderId': binderId,
            },
            query: {
                'targetItemId': targetItemId,
                'prevItemId': prevItemId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Toss payments 빌링키 등록
     * Toss payments UI 를 이용해서 본인인증 및 카드등록이 완료합니다.<br>
     * 이후 응답으로 받게 되는 `authKey`, `customerKey` 를 API 호출 시 전달해야 합니다.
     *
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static success({
        requestBody,
    }: {
        requestBody: IssueBillingKeyRequest,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binder-app/payments/billing-key',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * [바인더] 카카오 로그인 Token 발급 API
     * [로컬: 카카오 인증하기](http://localhost:8080/auth/binder/kakao/authorize) 접속
     *
     * @returns BasicResponseBinderAccessToken OK
     * @throws ApiError
     */
    public static signInViaKakao1({
        requestBody,
    }: {
        requestBody: BinderSignInViaKakaoCommand,
    }): CancelablePromise<BasicResponseBinderAccessToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/binder/kakao/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * [바인더] 카카오 로그인 Token 발급 API
     * @returns BasicResponseString OK
     * @throws ApiError
     */
    public static getKakaoAccessToken({
        requestBody,
    }: {
        requestBody: BinderSignInViaKakaoCommand,
    }): CancelablePromise<BasicResponseString> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/binder/kakao/accessToken',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 정보 조회
     * @returns BasicResponseGetBinderMemberResponse OK
     * @throws ApiError
     */
    public static getBinderMember(): CancelablePromise<BasicResponseGetBinderMemberResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder/member',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 결제수단 목록 조회
     * 사용자가 등록한 결제수단(카드) 목록을 조회합니다.
     *
     * @returns BasicResponseListPaymentMethodItem OK
     * @throws ApiError
     */
    public static findPaymentMethods(): CancelablePromise<BasicResponseListPaymentMethodItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/payments/payment-methods',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * customerKey 발급
     * 사용자 정보와 1:1로 매핑된 유니크한 `customerKey` 를 발행하는 API 입니다.<br>
     * Toss payments UI 로 카드등록 절차를 수행할 때, 클라이언트에서 임의의 customerKey 를 만들지 않고,<br>
     * API 에서 제공하는 customerKey 를 사용해야 합니다.
     *
     * @returns BasicResponseStringValueObject OK
     * @throws ApiError
     */
    public static getCustomerKey(): CancelablePromise<BasicResponseStringValueObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/payments/customer-key',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 구독 내역
     * 사용자의 구독 내역 리스트를 조회합니다.
     *
     * @returns BasicResponseListPaymentHistoryItem OK
     * @throws ApiError
     */
    public static getPaymentHistory({
        page,
        pageSize,
    }: {
        page: number,
        pageSize: number,
    }): CancelablePromise<BasicResponseListPaymentHistoryItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/members/me/subscriptions/payment-overviews',
            query: {
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 정보 및 설정값 조회
     * @returns BasicResponsePersonalSettings OK
     * @throws ApiError
     */
    public static getPersonalSettings(): CancelablePromise<BasicResponsePersonalSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/members/me/settings',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static removeBinder({
        binderId,
    }: {
        binderId: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/binders/{binderId}',
            path: {
                'binderId': binderId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 결제수단 삭제
     * 사용자가 등록한 결제수단(카드)을 삭제합니다.
     *
     * @returns BasicResponseBoolean OK
     * @throws ApiError
     */
    public static deletePaymentMethod({
        paymentMethodId,
    }: {
        paymentMethodId: string,
    }): CancelablePromise<BasicResponseBoolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/binder-app/payments/payment-methods/{paymentMethodId}',
            path: {
                'paymentMethodId': paymentMethodId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
